






import { createComponent, inject,　onMounted, onUnmounted } from "@vue/composition-api";
import Base from "@/views/Base.vue";

export default createComponent({
  components: {
    Base,
  },
  setup(props, context) {

    onMounted(() => {

    });
  },
});
