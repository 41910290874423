






import { createComponent, inject, onMounted } from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";

import NoticeListView from "@/components/templates/NoticeListView.vue";

export default createComponent({
  setup() {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }

    onMounted(async () => {
      displayui.debug_log("NoticeList.vue onMounted");

      await user.requestCustomerInfo();
    });

    return {  };
  },
  components: {
    NoticeListView,
  },
});
