import { reactive } from "@vue/composition-api";
import axios from "axios";
import qs from "qs";
import router from "@/router";
import _ from "lodash";
import { bootstrap } from "vue-gtag";

export default function jobStore() {
  // errorHandle();

  type state = {
    application_status_code: string;
  };
  const state = reactive({
    // job.loading
    loading: false,
    viewJobKey: "", // 直前に表示していた求人のキー
    jobError: {
      status: 200,
    },

    job: [],
    jobSortTab: 0,
    jobSortTarget: "",
    jobSortOrder: "",
    isJobSearchCompleted : false,
    jobShiftId: "",
    jobdetail: {
      company_id: "",
      address: "",
      job_information_id: "",
      common_id: "",
      work_start_date: "",
      start_time: "",
      shift_id: "",
      images: [],
      belongings: "",
      grooming: "",
      meeting_place: "",
      shift_list: [
        {
          start_time: "",
          end_time: "",
          break_minutes: "",
          position: "",
          salary: "",
          shift_id: "",
        },
      ],
      penalty_point: 0,
    },
    sumEvaluation: 0,
    applicationInfo: [],
    rating: {},
    evaluation_code: "",
    myrating: {},
    ing_rating: "",
    ing_comment: "",

    attendance: {
      customer_id: "",
      job_information_id: "",
      company_id: "",
      start_time: "",
      end_time: "",
      attendance_flg: "",
      attendance_reason: "",
      csv_after_work: "",
      regist_date: "",
      regist_user_id: "",
      update_date: "",
      update_user_id: "",
      delete_flg: "",
    },
    chat: {},
    job_post_tag: "",
    chatJustBefore: {},
    ing_message: "",
    jobAlert: {
      status: "",
      message: "",
    },
  });

  // 条件指定してジョブ一覧をソート
  function sortJobList(sortTarget:string, sortOrder:string) {

    // ソート処理
    state.job = _.orderBy(state.job, [sortTarget], [sortOrder]);

    // ソート条件保存
    state.jobSortTarget = sortTarget;
    state.jobSortOrder = sortOrder;

    // ソート条件によって代表シフトを選定
    for(let jobIndex=0; jobIndex<state.job.length; jobIndex++){
      const shiftList:any[] = state.job[jobIndex]['shift_list'];
      
      // 日給が高い順なら日給最高額シフトを代表シフトに設定
      if(sortTarget == "max_income" && sortOrder == "desc"){
        for(let shiftIndex=0; shiftIndex<shiftList.length; shiftIndex++){
          if(state.job[jobIndex]['max_income'] == state.job[jobIndex]['shift_list'][shiftIndex]['income']){
            state.job[jobIndex]['example_shift'] = state.job[jobIndex]['shift_list'][shiftIndex];
            break;
          }
        }
      }
      // 時給が高い順なら時給最高額シフトを代表シフトに設定
      else if(sortTarget == "max_salary" && sortOrder == "desc"){
        for(let shiftIndex=0; shiftIndex<shiftList.length; shiftIndex++){
          if(state.job[jobIndex]['max_salary'] == state.job[jobIndex]['shift_list'][shiftIndex]['salary']){
            state.job[jobIndex]['example_shift'] = state.job[jobIndex]['shift_list'][shiftIndex];
            break;
          }
        }
      }
      // それ以外は1つ目のシフトを代表シフトに設定
      else{
        state.job[jobIndex]['example_shift'] = state.job[jobIndex]['shift_list'][0];
      }
    }

    state.isJobSearchCompleted = true;
  }

  function sortByTabIndex(index:number) {
    // 日給降順
    if(index == 0){
      sortByIncome();
    }
    // 時給降順
    else if(index == 1){
      sortBySalary();
    }
    // 勤務日昇順
    else if(index == 2){
      sortByWorkDay();
    }
  }
  // sort 日給降順
  function sortByIncome() {
    sortJobList("max_income", "desc");
  }
  // sort 報酬降順
  function sortBySalary() {
    sortJobList("max_salary", "desc");
  }
  // sort 勤務日昇順
  function sortByWorkDay() {
    sortJobList("work_start_date", "asc");
  }
  // sort 応募締切昇順
  function sortByApplicationDeadLine() {
    sortJobList("posting_start_date", "asc");
  }

  const axiosinstance = axios.create({
    baseURL: process.env.VUE_APP_API_SERVER + "customer/v0/",
  });
  axiosinstance.defaults.timeout = 20000;
  axiosinstance.interceptors.response.use(
    function (response) {
      // 200 OK
      return response;
    },
    function (err) {
      //タイムアウトの場合
      const isTimeout = err.code === "ECONNABORTED";
      if (isTimeout) {
        state.loading = false;
        router.push("/timeout-error");
        return err;
      }

      //ネットワークエラーの場合
      if (!err.response) {
        state.loading = false;
        router.push("/network-error");
        return err;
      } else {
        state.loading = false;
        state.jobError = err.response;
      }
    }
  );

  return {
    get loading() {
      return state.loading;
    },
    get jobError() {
      return state.jobError;
    },

    getViewJobKey() {
      return state.viewJobKey;
    },
    clearViewJobKey() {
      state.viewJobKey = '';
    },
    setViewJobKey(company_id:string, common_id:string) {
      if(!company_id || !common_id){
        state.viewJobKey = '';
        return;
      }
      state.viewJobKey = company_id + '-' + common_id;
    },

    // joblist
    get joblist() {
      return state.job;
    },
    get jobSortTab() {
      return state.jobSortTab;
    },
    set jobSortTab(value:number) {
      state.jobSortTab = value;
    },
    get jobSortTarget() {
      return state.jobSortTarget;
    },
    get jobSortOrder() {
      return state.jobSortOrder;
    },
    get isJobSearchCompleted() {
      return state.isJobSearchCompleted;
    },

    get sumEvaluation() {
      return state.sumEvaluation;
    },

    // 求人一覧取得
    async jobjson() {
      state.loading = true;
      return axiosinstance
        .get("job")
        .then((response) => {
          state.job = response.data;
          state.isJobSearchCompleted = true;

          sortBySalary();
        })
        .then(() => {
          state.loading = false;
        });
      // .catch(err => {
      //   state.jobError = err;
      //   state.loading = false;
      // });
    },

    // 求人一覧取得 カスタム（検索条件で）
    async searchjobjson(params: any) {
      state.loading = true;
      const requestquery = params.value;
      await axiosinstance
        .get("job", {
          headers: {
            // Authorization: localStorage.getItem("token")
          },
          // params
          params: {
            kanto_codes: requestquery.kanto_codes.join(','),

            // 給与
            salary: requestquery.salary,

            // 企業ID
            company_id: requestquery.company_id,

            // フリーワード
            free_word: requestquery.free_word,

            // 職種カテゴリ小項目コード
            job_category_code: requestquery.job_category_code.join(','),

            // スキル・特徴カテゴリ小項目コード
            feature_code: requestquery.feature_code,

            // 勤務日
            work_days: requestquery.work_days.join(','),

            // 勤務時間（時間）検索From
            start_hr: requestquery.start_hr,

            // 勤務時間（分）検索From
            start_min: requestquery.start_min,

            // 勤務時間（時間）検索To
            end_hr: requestquery.end_hr,

            // 勤務時間（分）検索To
            end_min: requestquery.end_min,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        })
        .then((response) => {
          state.job = response.data;
          state.isJobSearchCompleted = true;

          sortByTabIndex(state.jobSortTab);
        })
        .then(() => (state.loading = false));
    },
    // sort
    sortByTabIndex(index:number) {
      sortByTabIndex(index);
    },
    // sort 勤務日昇順
    sortByWorkDay() {
      sortByWorkDay();
    },
    // sort 日額降順
    sortBySalary() {
      sortBySalary();
    },
    // sort 応募締切順
    sortByApplicationDeadLine() {
      sortByApplicationDeadLine();
    },

    // jobDetail 企業詳細
    get jobdetail() {
      return state.jobdetail;
    },
    get jobShiftId() {
      return state.jobShiftId;
    },

    // 就労日とshift_idからjob_information_idを特定
    async getJobInformationId(
      company_id: string,
      common_id: string,
      work_start_date: string,
      shift_id: string
    ) {
      state.loading = true;
      await axiosinstance
        .get("getJobInformationId", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            company_id: company_id,
            common_id: common_id,
            work_start_date: work_start_date,
            shift_id: shift_id,
          },
        })
        .then((response) => {
          state.jobdetail.job_information_id = response.data.job_information_id;
        })
        .then(() => {
          state.loading = false;
        });
    },

    // 求人一覧から仕事を選択（detail取得）GET
    async decideJob(company_id: string, job_information_id = "", common_id = "", shift = "") {
      state.loading = true;
      await axiosinstance
        .get("jobDetail", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            company_id: company_id,
            job_information_id: job_information_id,
            common_id: common_id,
            shift: shift,
          },
        })

        .then((response) => {
          state.jobdetail = response.data;

          // TODO:jobdetail.shift_idがここの設定後にundefinedになる場合があるため別の変数に保持
          state.jobShiftId = response.data.shift_id;

          if (!job_information_id) {
            job_information_id = response.data.job_information_id;
          }
          state.jobdetail.images = [];
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          state.jobdetail.images[0] = response.data.media_id_1;
          if (response.data.media_id_2) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            state.jobdetail.images[1] = response.data.media_id_2;
          }
          if (response.data.media_id_3) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            state.jobdetail.images[2] = response.data.media_id_3;
          }
        })
        .then(() => {
          bootstrap().then((gtag: any) => {
            gtag("config", "GTM-MPPP62T", {
              page_path: "/job-detail/" + company_id + "/" + job_information_id,
            });
            // gtag("event", "click_job_detail", {
            //   "job-information-id": j
            // });
          // ローカル環境のみ、gtag is not a functionエラー
          }).catch(err => {});
        })
        .then(() => (state.loading = false));
    },

    // 企業評価取得GET
    getRating(c: string, job_information_id: string) {
      if (!localStorage.getItem("token")) {
        return;
      }
      if (!c) {
        return;
      }
      state.loading = true;
      axiosinstance
        .get("rating", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            company_id: c,
            job_information_id: job_information_id,
          },
        })

        .then((response) => (state.rating = response.data))
        .then((res) => {
          // state.sumEvaluation = res.reduce((r: any, t: any) => {
          //   return r + parseFloat(t.evaluation_code);
          // }, 0);
        })

        .then(() => (state.loading = false));
      // .catch(err => {
      //   state.jobError = err;
      //   state.loading = false;
      // });
    },
    // 企業評価の合計値取得GET
    getRatingTotal(c: string) {
      state.loading = true;
      axiosinstance
        .get("rating", {
          // ログイン前でも使えるようコメントアウト
          // headers: {
          //   Authorization: localStorage.getItem("token"),
          // },
          params: {company_id:c},
        })

        .then((response) => (state.rating = response.data))
        .then()

        .then(() => (state.loading = false));
    },
    // 自分の企業評価一覧を取得する
    async getMyRating(c: string, j: string) {
      if (!localStorage.getItem("token")) {
        return;
      }
      state.loading = true;
      await axiosinstance
        .get("member/job-rating/myrating", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            company_id: c,
            job_information_id: j,
          },
        })

        .then((response) => (state.myrating = response.data))
        .then(() => (state.loading = false));
      // .catch(err => {
      //   state.jobError = err;
      //   state.loading = false;
      // });
    },

    // 企業評価する POST myrating
    postMyRating(c: string, j: string, iineItem: Array<number>) {
      const params = {
        company_id: c,
        job_information_id: j,
        rating_1: iineItem[0],
        rating_2: iineItem[1],
        rating_3: iineItem[2],
      };

      axiosinstance
        .post("member/job-rating/myrating", params, {
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })

        .then((response) => {
          state.myrating = response.data;
          (state.jobAlert.status = "評価完了"),
            (state.jobAlert.message = "評価完了しました");
        })
        .then(() => this.clearTextBox())
        // .catch(err => {
        //   state.jobError = err;
        //   state.loading = false;
        // })
        .then(() => (state.loading = false));
    },
    // myrating ストア
    storeRating(e: any) {
      return (state.ing_rating = e);
    },
    // myrating comment ストア
    storeComment(e: any) {
      return (state.ing_comment = e);
    },

    // 企業評価
    get rating() {
      return state.rating;
    },
    // 企業評価 私の
    get myrating() {
      return state.myrating;
    },
    // レイティング入力中
    get ing_rating() {
      return state.ing_rating;
    },
    // コメント入力中
    get ing_comment() {
      return state.ing_comment;
    },
    // ratingのクリア（入力初期化）
    clearTextBox() {
      return (state.ing_rating = ""), (state.ing_comment = "");
    },
    // 勤怠状況 ATTENDANCE
    get attendance() {
      return state.attendance;
    },
    // 勤怠状況 GET
    getAttendance(c: string, j: string) {
      if (!c) {
        return;
      }
      if (!localStorage.getItem("token")) {
        return;
      }
      state.loading = true;
      axiosinstance
        .get("jobpayAttendance", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            company_id: c,
            job_information_id: j,
          },

        })
        .then((response) => (state.attendance = response.data))
        .then(() => (state.loading = false));
      // .catch(err => {
      //   state.jobError = err;
      //   state.loading = false;
      // });
    },
    // チャット
    get chat() {
      return state.chat;
    },
    get chatJustBefore() {
      return state.chatJustBefore;
    },
    // チャットGET
    getChat(c: string, j: string) {
      if (!localStorage.getItem("token")) {
        return;
      }
      state.loading = true;
      axiosinstance
        .get("chat", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            company_id: c,
            job_information_id: j,
          },
        })

        .then((response) => {
          if (!response.data) {
            return;
          } else {
            state.chat = response.data;
          }
        })
        .then(() => (state.loading = false));
      // .catch(err => {
      //   state.jobError = err;
      //   state.loading = false;
      // });
    },
    // チャットPOST
    postChat(c: string, j: string, message: string, common_id: string) {
      state.loading = true;
      const ranges = [
        "\ud83c[\udf00-\udfff]",
        "\ud83d[\udc00-\ude4f]",
        "\ud83d[\ude80-\udeff]",
        "\ud7c9[\ude00-\udeff]",
        "[\u2600-\u27BF]",
      ];
      const reg = new RegExp(ranges.join("|"), "g");
      message = message.replace(reg, " ");

      const params = {
        company_id: c,
        job_information_id: j,
        message: message,
        common_id: common_id,
      };
      if (message) {
        axiosinstance
          .post("chat", params, {
            headers: {
              "content-type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          })

          .then((response) => {
            if (!response.data) {
              state.chatJustBefore = {};
            } else {
              state.chatJustBefore = response.data;
            }
          })
          .then(() => {
            this.getChat(c, j);
          })
          .then(() => {
            this.clearChat();
          })
          .then(() => (state.loading = false));
      } else {
        state.loading = false;
      }
    },
    // レイティング入力中
    get ing_message() {
      return state.ing_message;
    },
    // チャットmessage ストア
    storeMessage(t: any) {
      return (state.ing_message = t);
    },
    // messageのクリア（入力初期化）
    clearChat() {
      return (state.ing_message = "");
    },

    // jobAlert
    get jobAlert() {
      return state.jobAlert;
    },

    // jobAlert 解除
    resetJobAlert() {
      return (state.jobAlert.status = ""), (state.jobAlert.message = "");
    },

    setJobAlert(s: string, m: string) {
      state.jobAlert.status = s;
      state.jobAlert.message = m;
    },

    // googleしごと検索用処理
    get jobPostTag() {
      return state.job_post_tag;
    },
    jobHTML(company_id: string, common_id: string, job_information_id: string) {
      state.loading = true;
      axiosinstance
        .get("createGoogleJobSearchTag", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            company_id: company_id,
            common_id: common_id,
            job_information_id: job_information_id,
          },
        })
        .then((response) => {
          if (!response.data) {
            return;
          } else {
            state.job_post_tag = response.data;
          }
        })
        .then(() => {
          state.loading = false;
        });
    },
  };
}

export type JobStore = ReturnType<typeof jobStore>;
