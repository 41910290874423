import { reactive } from "@vue/composition-api";
import { SALARY, STARTENDTIME } from "@/constants/choices";
import axios from "axios";
import qs from "qs";
import _ from "lodash";
import router from "@/router";
// import errorHandle from "@/lib/errorHandle.ts";

interface KantoList 
{
  id: number,
  name: string,
  parent: boolean,
  children: [
    {
      id: number,
      code: string,
      name: string,
      parent: boolean
    },
  ]
}

interface Jobcategorylist
{
  id: number,
  parent: boolean,
  children: [
    {
      id: number,
      parent: boolean,
      job_middle_name: string,
      children: [
        {
          id: number,
          parent: boolean,
          job_small_code: string,
          job_small_name: string,
          job_small_image: string,
          job_small_explain: string,
          job_small_beginner_flg: boolean
        }
      ]
    }
  ]
}

interface jobCategorySelected
{
  id: number,
  job_small_beginner_flg: boolean,
  job_small_code: string,
  job_small_explain: string,
  job_small_image: string,
  job_small_name: string,
  parent: boolean,
}[]

interface featureSelected
{
  small_code: string,
  small_name: string
}[]

export default function searchStore() {
  // errorHandle();
  // state初期値
  const state = reactive({
    // search.loading
    loading: false,
    searchError: {
      status: 200,
    },
    searchAlert: {
      status: "",
      message: "",
    },

    //検索パラメータ
    searchParams: {},

    // 日額
    salarylist: SALARY,
    salary: "",

    // 企業ID
    companyId: "",

    // フリーワード
    freeWord: "",

    // 勤務日
    workDays: <string[]> [],

    // 勤務時間
    workingTime: STARTENDTIME,
    workingStartTime: "",
    workingEndTime: "",

    // 勤務地
    locale: {
      id: [0],
      kanto_codes: <string[]> [],
      kanto_names: <string[]> [],
    },

    kantoList: <KantoList[]> [],

    // 職種
    job_category_code: "",
    jobCategoryName: [""],
    jobcategoryrawlist: [],
    jobcategorylist: <Jobcategorylist[]> [],
    jobmiddlecategorylist: [],
    jobcategoryTransferDict: <any> {},

    jobCategory: {
      id: [0],
      codes: [""],
      names: <string[]> []
    },

    jobCategorySelected: <jobCategorySelected[]> [],

    // 特徴
    feature: [{}],
    featureCode: <string[]> [],
    featurelist: [""],
    featureName:  <string[]> [],
    featureSelected: <featureSelected[]> []
  });

  // APIサーバ
  const axiosinstance = axios.create({
    baseURL: process.env.VUE_APP_API_SERVER + "customer/v0/",
  });
  axiosinstance.defaults.timeout = 20000;
  axiosinstance.interceptors.response.use(
    function (response) {
      // 200 OK
      return response;
    },
    function (err) {
      //タイムアウトの場合
      const isTimeout = err.code === "ECONNABORTED";
      if (isTimeout) {
        state.loading = false;
        router.push("/timeout-error");
        return err;
      }

      //ネットワークエラーの場合
      if (!err.response) {
        state.loading = false;
        router.push("/network-error");
        return err;
      } else {
        state.loading = false;
        state.searchError = err.response;
      }
    }
  );

  // 勤務地の決定 store
  const decideLocale = (selection: Number[]) => {
    let selected:any[] = []
    for (const locateItem of state.kantoList) {
      const selectedOne = locateItem["children"].filter((locate) => {
        return selection.includes(locate.id);
      });
      selected = selected.concat(selectedOne);
    }

    state.locale.id = selected.map(select => select.id);
    state.locale.kanto_codes = selected.map(select => select.code);
    state.locale.kanto_names = selected.map(select => select.name);
  };

  // 選択したら、storeにid,code,nameを保存
  const decideJobCategory = (selection: Number[]) => {
    const jobcategorylist = state.jobcategorylist[0]?.children;

    let selected:any[] = [];
    for (const category of jobcategorylist) {
      const selected_tmp = category?.children.filter((child) => {
        return selection.includes(child.id);
      });
      selected.push(selected_tmp);
    }
    selected = selected.flat();

    state.jobCategory.id = selected.map(select => select.id);
    state.jobCategory.codes = selected.map(select => select.job_small_code);
    state.jobCategory.names = selected.map(select => select.job_small_name);
    state.jobCategorySelected = selected;
  };

  // 特徴を選択する(複数指定)
  const decideFeatures = (selection: String[]) => {
    state.featureCode = [];
    state.featureName = [];
    state.featureSelected = [];

    for(const majorFeatureKey in state.featurelist) {
      const majorFeatureList = state.featurelist[majorFeatureKey];
      for(let listIndex = 0; listIndex < majorFeatureList.length; listIndex++){
        const featureItem:any = majorFeatureList[listIndex];
        const code = featureItem["small_code"];
        const name = featureItem["small_name"];

        if(selection.includes(code)){
          state.featureCode.push(code);
          state.featureName.push(name);
          state.featureSelected.push({'small_code': code, 'small_name': name});
        }
      }
    }
  };

  // 特徴を選択・選択解除する(単体)
  const decideFeature = (code: string, name: string) => {
    if (state.featureCode.includes(code)) {
      state.featureCode = state.featureCode.filter((value) => {
        return value !== code;
      });
      state.featureName = state.featureName.filter((value) => {
        return value !== name;
      });
      state.featureSelected = state.featureSelected.filter((value) => {
        return value.small_code !== code;
      })
    }
    // チェックを入れる
    else {
      state.featureCode.push(code);
      state.featureName.push(name);
      state.featureSelected.push({'small_code': code, 'small_name': name});
    }
  };

  // 指定した検索条件を画面に設定
  function setJobSearchData(job_search_data:any, jobCategoryTransferDict:any) {
    clearSearchParams();

    // 勤務地
    // 旧版検索条件対応
    if(job_search_data.kanto_ids){
      job_search_data.loc = job_search_data.kanto_ids;
    }
    if(job_search_data.loc && job_search_data.loc.length > 0){
      const localeSelection:Number[] = job_search_data.loc.split(',').map((str: string) => parseInt(str, 10));
      decideLocale(localeSelection);
    }

    if(job_search_data.salary && job_search_data.salary !== ""){
      state.salary = job_search_data.salary;
    }
    if(job_search_data.cpi && job_search_data.cpi !== ""){
      state.companyId = job_search_data.cpi;
    }
    if(job_search_data.free_word && job_search_data.free_word !== ""){
      state.freeWord = decodeURIComponent(job_search_data.free_word);
    }

    // 職種
    // 旧版検索条件対応
    if(job_search_data.job_category_code){
      job_search_data.jcc = job_search_data.job_category_code;
    }
    // 廃止した職種小コードの移行のための職種コード新旧対応定義
    if(job_search_data.jcc && job_search_data.jcc.length > 0){
      // 古い職種小コードを差し替え
      const jobCategoryCodes = job_search_data.jcc.split(',');
      for (let jccIndex = 0; jccIndex < jobCategoryCodes.length; jccIndex++) {
        for (const newCategorySmallCode in jobCategoryTransferDict) {
          if(jobCategoryTransferDict[newCategorySmallCode]['old_small_codes'].indexOf(jobCategoryCodes[jccIndex]) !== -1){
            jobCategoryCodes[jccIndex] = newCategorySmallCode;
          }
        }
      }
      state.jobCategory.codes = jobCategoryCodes;

      // idをキーに画面チェックボックスを動作させているので職種コードから生成
      const jobCategoryIds:any = [];
      if(state.jobcategorylist.length > 0){
        for (const jobcategoryMiddleItem of state.jobcategorylist[0].children) {
          for (const jobcategorySmallItem of jobcategoryMiddleItem.children) {
            if (jobCategoryCodes.indexOf(jobcategorySmallItem.job_small_code) !== -1) {
              jobCategoryIds.push(jobcategorySmallItem.id);
            }
          }
        }
      }
      const jobCategorySelection:Number[] = jobCategoryIds.map((str: string) => parseInt(str, 10));
      decideJobCategory(jobCategorySelection);
    }
    
    // 特徴
    // 旧版検索条件対応
    if(job_search_data.feature_code){
      job_search_data.feature = job_search_data.feature_code;
    }
    if(job_search_data.feature){
      const featureSelectionCodes = job_search_data.feature.split(',');
      decideFeatures(featureSelectionCodes);
    }

    // 旧版検索条件対応
    if(job_search_data.work_days){
      job_search_data.wdate = job_search_data.work_days;
    }
    if(job_search_data.wdate){
      try{
        // 旧形式でデコード
        state.workDays = JSON.parse(decodeURIComponent(job_search_data.wdate));
      } catch(e){
        // 新形式でデコード
        state.workDays = job_search_data.wdate.split(',');
      }
    }

    // 旧版検索条件対応
    if(job_search_data.start_time){
      job_search_data.wtimef = decodeURIComponent(job_search_data.start_time).replace(":","");
    }
    if(job_search_data.wtimef){
      state.workingStartTime = job_search_data.wtimef.substr(0, 2) + ':' + job_search_data.wtimef.substr(2, 2);
    }

    // 旧版検索条件対応
    if(job_search_data.end_time){
      job_search_data.wtimet = decodeURIComponent(job_search_data.end_time).replace(":","");
    }
    if(job_search_data.wtimet){
      state.workingEndTime = job_search_data.wtimet.substr(0, 2) + ':' + job_search_data.wtimet.substr(2, 2);
    }
  }

  // 画面から検索条件を取得
  function getJobSearchData(){
    const params:any = {};
    // 勤務地
    if(state.locale.id.length > 0){
      params.loc = state.locale.id.join(',');
    }
    // 給与
    if(state.salary !== ""){
      params.salary = state.salary;
    }
    // 企業
    if(state.companyId !== ""){
      params.cpi = state.companyId;
    }
    // フリーワード
    if(state.freeWord !== ""){
      params.free_word = encodeURIComponent(state.freeWord);
    }
    // 職種カテゴリ小項目コード
    if(state.jobCategory.codes.length > 0){
      params.jcc = state.jobCategory.codes.join(',');
    }

    // 特徴
    if(state.featureCode.length > 0){
      params.feature = state.featureCode.join(',');
    }

    // 勤務日
    if(state.workDays.length > 0){
      params.wdate = state.workDays.join(',');
    }
    // 勤務時間From
    if(state.workingStartTime !== ""){
      params.wtimef = state.workingStartTime.replace(":", "");
    }
    // 勤務時間To
    if(state.workingEndTime !== ""){
      params.wtimet = state.workingEndTime.replace(":", "");
    }

    return params;
  }

  // 検索パラメータリセット
  function clearSearchParams(isAll:boolean=false) {

    // 勤務地
    (state.locale.id = []),
    (state.locale.kanto_codes = []),
    (state.locale.kanto_names = []),
    // 給与
    (state.salary = ""),
    // 職種
    (state.jobCategorySelected = []),
    (state.jobCategory.id = []),
    (state.jobCategory.codes = []),
    (state.jobCategory.names = []),
    // スキル・特徴カテゴリ小項目コード
    (state.featureCode = []),
    (state.featureName = []),
    (state.featureSelected = []),
    // 勤務日
    (state.workDays = []),
    // 勤務時間（時間）検索From
    (state.workingStartTime = ""),
    // 勤務時間（分）検索From
    (state.workingStartTime = ""),
    // 勤務時間（時間）検索To
    (state.workingEndTime = ""),
    // 勤務時間（分）検索To
    (state.workingEndTime = ""),
    // パラメータ全体
    (state.searchParams = "");
    // フリーワード
    state.freeWord = "";

    // 全てクリアする場合
    if(isAll){
      // 企業ID
      state.companyId = "";
    }
  }

  return {
    get loading() {
      return state.loading;
    },

    get searchError() {
      return state.searchError;
    },

    // keyの値から目的のオブジェクトを探す（ex. 県コードと県名）
    objectFindByKey(array: any, key: any, value: any) {
      for (let i = 0; i < array.length; i++) {
        if (array[i][key] === value) {
          return array[i];
        }
      }
      return null;
    },

    // searchAlert
    get searchAlert() {
      return state.searchAlert;
    },

    // searchAlert 解除
    resetSearchAlert() {
      return (state.searchAlert.status = ""), (state.searchAlert.message = "");
    },

    setSearchAlert(s: string, m: string) {
      state.searchAlert.status = s;
      state.searchAlert.message = m;
    },

    ////////////////////

    getSelectedLocaleIdList():Number[]{
      return state.locale.id;
    },

    // 検索する日額
    get salary() {
      return state.salary;
    },
    // 日額リスト（from CONSTANTS）
    get salarylist() {
      return state.salarylist;
    },

    // 企業ID
    get companyId() {
      return state.companyId;
    },

    // フリーワード
    get freeWord() {
      return state.freeWord;
    },

    // 日額の決定 store
    decideSalary(salaryvalue: string) {
      return (state.salary = salaryvalue);
    },

    // 企業ID入力
    inputCompanyId(value: string) {
      state.companyId = value;
    },

    // フリーワード入力
    inputFreeword(value: string) {
      state.freeWord = value;
    },

    // 勤務開始終了時間リスト取得
    get workingTime() {
      return state.workingTime;
    },
    // 勤務開始時間取得
    get workingStartTime() {
      return state.workingStartTime;
    },
    // 勤務終了時間取得
    get workingEndTime() {
      return state.workingEndTime;
    },

    // 勤務開始時間決定
    decideWorkingStartTime(starttime: string) {
      return (state.workingStartTime = starttime), (state.workingEndTime = "");
    },

    // 勤務終了時間決定
    decideWorkingEndTime(endtime: string) {
      return (state.workingEndTime = endtime);
    },

    get locale() {
      return state.locale;
    },
    
    get kantoList() {
      return state.kantoList;
    },

    // 勤務地の決定 store
    decideLocale(selection: Number[]) {
      decideLocale(selection);
    },

    notSelectLocale() {
      (state.locale.id = [0]), (state.locale.kanto_codes = []), (state.locale.kanto_names = []);
    },

    // 勤務日
    get workDays() {
      return state.workDays;
    },

    // 勤務日決定
    decideWorkDays(workDays: string[]) {
      state.workDays = workDays;
    },

    decideNotWorkdays() {
      state.workDays = [];
    },

    // システム情報取得
    async requestSystemInfo() {
      state.loading = true;
      await axiosinstance
        .get("systeminfo")
        .then((response) => {
          const responseData = response.data

          state.kantoList = responseData.search_city_tree;
          state.jobcategorylist = responseData.job_category_tree;
          state.jobcategoryTransferDict = responseData.job_category_transfer_dict;
          const featurelist = responseData.feature_list;
          state.featurelist = _.groupBy(featurelist, "major_code")
        })
        .then(() => (state.loading = false))
        .catch((err) => {
          state.searchError = err.response;
          state.loading = false;
        });
    },

    // 職種リスト
    get jobcategorylist() {
      return state.jobcategorylist;
    },
    // 職種カテゴリ（中）リスト
    get jobmiddlecategorylist() {
      return state.jobmiddlecategorylist;
    },
    // 職種(code)
    get jobCategory() {
      _.pull(state.jobCategory, "");
      return state.jobCategory;
    },
    // 職種(name)
    get jobCategoryName() {
      _.pull(state.jobCategoryName, "");
      return state.jobCategoryName;
    },

    get jobCategorySelected() {
      return state.jobCategorySelected;
    },
    // 職種を決定する（ストアする）
    decideJobCategory(selection: Number[]) {
      decideJobCategory(selection);
    },

    getSelectedJobCategoryIdList():Number[]{
      return state.jobCategory.id;
    },

    decideNotSelectJobCategory() {
      (state.jobCategory.id = [0]), (state.jobCategory.names = []);
    },

    // 特徴
    get feature() {
      _.pull(state.feature, "");
      return state.feature;
    },
    get featureSelected() {
      return state.featureSelected;
    },
    get featureName() {
      _.pull(state.featureName, "");
      return state.featureName;
    },
    get featureCode() {
      return state.featureCode;
    },

    get featurelist() {
      return state.featurelist;
    },
    // 特徴を決定する（ストアする）
    decideFeature(code: string, name: string) {
      decideFeature(code, name);
    },
    decideFeatures(selection: String[]) {
      decideFeatures(selection);
    },

    decideNotSelectFeature() {
      (state.featureCode = []), (state.featureName = []), (state.featureSelected = []);
    },

    // 検索するリクエストパラメータ
    get searchParams() {
      return state.searchParams;
    },

    // 現状の検索条件からURL用クエリマップを生成
    getSearchQueryMap() {
      return getJobSearchData();
    },

    // クエリから検索条件を復元
    loadJobSearchFromQuery(query:any) {
      const jobSearchData = query;

      // 企業絞り込みも含めてクリア
      clearSearchParams(true);
      // 検索条件設定
      setJobSearchData(jobSearchData, state.jobcategoryTransferDict);
    },

    //データを集約
    collectData() {
      const tempObj = {
        kanto_codes: state.locale.kanto_codes,

        // 給与
        salary: state.salary,

        // 企業ID
        company_id: state.companyId,

        // フリーワード
        free_word: state.freeWord,

        // 職種カテゴリ小項目コード
        job_category_code: state.jobCategory.codes,

        // スキル・特徴カテゴリ小項目コード
        feature_code: state.featureCode,

        // 勤務日
        work_days: state.workDays,

        // 勤務時間（時間）検索From
        start_hr: state.workingStartTime.split(":")[0],

        // 勤務時間（分）検索From
        start_min: state.workingStartTime.split(":")[1],

        // 勤務時間（時間）検索To
        end_hr: state.workingEndTime.split(":")[0],

        // 勤務時間（分）検索To
        end_min: state.workingEndTime.split(":")[1],
      };
      state.searchParams = tempObj;
    },

    // 検索パラメータリセット
    clearSearchParams(isAll:boolean=false) {
      clearSearchParams(isAll);
    },

    // 検索条件をサーバーへ保存
    saveJobSearch() {
      state.loading = true;

      const params = getJobSearchData();

      axiosinstance
        .post("jobSearch", 
        {"job_search_data": JSON.stringify(params)},
          {headers: { Authorization: localStorage.getItem("token") }}
        )
        .then(() => {
          state.loading = false;
          this.setSearchAlert("failGetJobSearch", "検索条件を保存しました");
        })
        .catch((err) => {
          state.searchError = err;
          state.loading = false;
        });
    },

    // 検索条件をサーバーからロード
    loadJobSearch() {
      state.loading = true;
      axiosinstance
        .get("jobSearch", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          const jobcategoryTransferDict = response.data.job_category_transfer_dict;
          const jobSearchData = response.data.job_search_data;
          if (! jobSearchData) {
            this.setSearchAlert("failGetJobSearch", "保存条件が存在しません");
          } else {
            setJobSearchData(jobSearchData, jobcategoryTransferDict);
          }
        })
        .then(() => (state.loading = false))
        .catch((err) => {
          state.searchError = err;
          state.loading = false;
        });
    }
  };
}

export type SearchStore = ReturnType<typeof searchStore>;
