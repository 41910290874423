import { reactive } from "@vue/composition-api";
import axios from "axios";
import { BANK_KANA } from "@/constants/choices";
import _ from "lodash";
import router from "@/router";
import { MONTH } from "@/constants/choices";
import filter from "@/lib/filters";
// import displayUIStore from "./displayui";
// import errorHandle from "@/lib/errorHandle.ts";

import { LOGIN_LINK_SETTINGS } from "@/constants/pageview";

export default function userStore() {
  // errorHandle();
  type state = {
    application_status_code: string;
  };

  const state = reactive({
    // user.loading
    dialog: {
      result: false,
      message: "",
      title: "",
    },
    loading: false,
    userError: {
      status: 200,
      statusText: "",
      data: {
        detail: ""
      }
    },
    loginError: {
      status: 200,
      data: {
        detail: "",
      },
    },
    inquiryError: {
      status: 200,
      data: {
        name: [],
        mail_address: [],
        tel: [],
        content: [],
      },
    },
    loginParams: {},
    // システム情報(現状、APIから取得できていないパターンあり？)
    systeminfo: {
      recruitment_closing_hour: 4,
      recruitment_enable_change_hour: 3,
      recruitment_worker_decline_enable_hour: 12
    },
    userinfo: {
      login_id:"",
      card_number: "",
      password: "",
      bank_id: "",
      url: "",
      bank_image: null,
      user_image_url: "",
      agreement_flg: "",
      delete_flg: "0",
      jobpay_registered_flg: 0,
      jobpay_activation_flg: 0,
      orientation: 1,
      user_image: null,
      user_rating: 0,
      recommend_flg: 1,
    },
    customerInfo: {
      id: "",
      card_number: "",
      mail_address: "",
      name: "",
      name_first: "",
      name_last: "",
      name_kana: "",
      name_kana_first: "",
      name_kana_last: "",
      nameKana: "",
      zipcode: "",
      address1: "",
      address2: "",
      phone: "",
      sex_code: "",
      gender: "",
      birth_ymd: "",
      nationality: "",
      peps: "",
      jobpay_entry_key: "",
      jobpay_entry_mail_address: ""
    },

    unratedDialogFlg: false,
    unratedJobInfo: {},

    // キャンペーン情報
    campaignInfo: {},

    campaignBannerInfo: {
      href:"",
      img_url:""
    },

    application: {},
    applicationHistory: [
      {
        application_status_code: "",
      },
    ],
    applicationHistoryAndStatusCode: [
      {
        application_status_code: "",
      },
    ],
    applicationHistoryDate: [
      {
        date: "",
        date_status: "",
      },
    ],
    applicationInfo: [
      {
        application_status_code: "",
      },
    ],
    penalty_point: 0,
    same_date_adoption_flg:0,
    favorite: {},
    favoritelist: {},
    favoriteIds: [],

    notice_page_no: 1,
    notice_page_max_no: 1,
    notice_item: null,
    notice_list: [],
    notice_count: {
      all: 0,
      unread: 0,
    },

    salarylist: {},
    workYear: 1900,
    workMonth: 0,
    monthlist: MONTH,

    login_status: false,
    agreement_flg: "",
    bank: {
      bank_no: "",
      bank_name: "",
      branch_no: "",
      branch_name: "",
      account_no: "",
      account_type: "1",
      account_holder: "",
    },
    bankAccount: {
      bank_no: "",
      branch_no: "",
      account_no: "",
      account_holder: "",
      account_type: "",
      request_date: "",
    },
    akasatana: BANK_KANA,
    akasatanaDetail: [{}],
    bank_kana: "",
    refinedLetter: "",
    refinedlist: {},
    refinedbranchlist: {},
    netbanklist: {},
    citybanklist: {},
    banklist: {
      // bank_kana:""
    },
    bankbranchlist: {},
    skilllist: [],
    skilllistCategory: {},
    userSkill: [{}],
    skillValues: [{}],
    userAlert: {
      status: "",
      message: "",
    },
    inquiryInfo: {
      name: "",
      mail_address: "",
      tel: "",
      content: "",
      complete: false,
    },
    report: new (class {
      public WithholdingSlip = new (class {
        public selected_year: number;

        constructor() {
          this.selected_year = new Date().getFullYear();
        }
      })();
      public PaySlip = new (class {
        public monthlist = [
          { id: 13, value: 1 },
          { id: 14, value: 2 },
          { id: 15, value: 3 },
          { id: 16, value: 4 },
          { id: 17, value: 5 },
          { id: 18, value: 6 },
          { id: 19, value: 7 },
          { id: 20, value: 8 },
          { id: 21, value: 9 },
          { id: 22, value: 10 },
          { id: 23, value: 11 },
          { id: 24, value: 12 },
        ];
        public selected_year: number;
        public selected_month: number;

        constructor() {
          this.selected_year = new Date().getFullYear();
          this.selected_month = new Date().getMonth() + 1;
        }
      })();
    })(),
  });

  const formData = new FormData();
  const axiosinstance = axios.create({
    baseURL: process.env.VUE_APP_API_SERVER + "customer/v0/",
  });
  axiosinstance.defaults.timeout = 20000;
  axiosinstance.interceptors.response.use(
    function (response) {
      // 200 OK
      return response;
    },
    function (err) {
      //タイムアウトの場合
      const isTimeout = err.code === "ECONNABORTED";
      if (isTimeout) {
        state.loading = false;
        router.push("/timeout-error");
        return err;
      }

      //ネットワークエラーの場合
      if (!err.response) {
        state.loading = false;
        router.push("/network-error");
        return err;
      } else {
        state.loading = false;
        state.userError = err.response;
      }
    }
  );

  const axiosCustomerInfo = axios.create({
    baseURL: process.env.VUE_APP_API_SERVER + "customer/v0/",
  });
  axiosCustomerInfo.defaults.timeout = 20000;
  axiosCustomerInfo.interceptors.response.use(
    function (response) {
      // 200 OK
      return response;
    },
    function (err) {
      //タイムアウトの場合
      const isTimeout = err.code === "ECONNABORTED";
      if (isTimeout) {
        state.loading = false;
        router.push("/timeout-error");
        return err;
      }

      //ネットワークエラーの場合
      if (!err.response) {
        state.loading = false;
        router.push("/network-error");
        return err;
      } else {
        state.loading = false;
      }
    }
  );

  const axiosForLogin = axios.create({
    baseURL: process.env.VUE_APP_API_SERVER + "customer/v0/",
  });
  axiosForLogin.defaults.timeout = 120000;

  axiosForLogin.interceptors.response.use(
    function (response) {
      // 200 OK
      return response;
    },
    function (err) {
      //タイムアウトの場合
      const isTimeout = err.code === "ECONNABORTED";
      if (isTimeout) {
        router.push("/timeout-error");
        return err;
      }

      //ネットワークエラーの場合
      if (!err.response) {
        router.push("/network-error");
        return err;
      }

      const statusCode = err.response.status;
      const apiResponse = err.response.data;
      const apiResponseData = apiResponse.data;

      // 初回ログインの場合
      if (apiResponse.response_code == '11') {
        alert(apiResponse.message)
        router.push({
          name: "AuthPasswordUpdateInputToken",
          params: { token: apiResponseData.token }
        });
      }
      else{
        state.loginError = {
          status: statusCode,
          data: {
            detail: apiResponse.message,
          }
        };
      }

    }
  );

  const axiosinstanceForInquiry = axios.create({
    baseURL: process.env.VUE_APP_API_SERVER + "customer/v0/",
  });
  axiosinstanceForInquiry.defaults.timeout = 20000;

  axiosinstanceForInquiry.interceptors.response.use(
    function (response) {
      // 200 OK
      return response;
    },
    function (err) {
      //タイムアウトの場合
      const isTimeout = err.code === "ECONNABORTED";
      if (isTimeout) {
        state.loading = false;
        router.push("/timeout-error");
        return err;
      }

      //ネットワークエラーの場合
      if (!err.response) {
        state.loading = false;
        router.push("/network-error");
        return err;
      } else {
        state.loading = false;
        state.inquiryError = err.response;
      }

      // バリデーションエラー
      // if(err.response.status == 400) {
      //   state.loading = false;
      //   state.inquiryError = err.response;
      // } else {
      //   state.loading = false;
      //   state.userError = err.response;
      // }
    }
  );

  const axiosForToken = axios.create({
    baseURL: process.env.VUE_APP_API_SERVER + "token/",
  });
  axiosForToken.defaults.timeout = 20000;

  const regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const regexp_makeLink = function (all: any, url: any, h: any, href: any) {
    return '<a href="h' + href + '" target="_blank">' + url + "</a>";
  };

  // デプロイ時のエラー回避のためFunction constructor(evalの代わり)経由でconsole.logを実行
  const debug_log_func = new Function('msg', 'console.log(msg);');

  // デバッグ出力
  const debug_log = function(msg:any) {
    // デバッグ出力がonの場合のみ、ログを出力
    const vp_log_debug_enabled = localStorage.getItem('vp/log/debug_enabled');
    if(vp_log_debug_enabled == '1'){
      debug_log_func(msg);
    }
  };

  // ワーカー情報取得
  const requestCustomerInfo = async function() {

    state.loading = true;
    await axiosCustomerInfo
      .get("customer_info", {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(async(response) => {
        // ログインしていない場合はスキップ
        if(!response.data){
          debug_log('requestCustomerInfo login_status=false');
          state.login_status = false;
          return;
        }
        debug_log('requestCustomerInfo login_status=true');

        const apiResponse = response.data;
        const apiResponseData = apiResponse;

        // ログイン状態のフロント側管理タイミング(refresh)が微妙なので、このAPIで情報取得できた場合もログインOK判定する
        state.login_status = true;

        state.customerInfo = apiResponseData;

        // 顔写真画像情報再取得
        state.userinfo.user_image_url = apiResponseData.user_image_path;
        state.userinfo.orientation = apiResponseData.orientation;
        // リコメンド設定再取得
        state.userinfo.recommend_flg = apiResponseData.recommend_flg;

        state.userinfo.jobpay_registered_flg = apiResponseData.jobpay_registered_flg;
        state.userinfo.jobpay_activation_flg = apiResponseData.jobpay_activation_flg;

        // 規約同意状態取得
        state.userinfo.agreement_flg = apiResponseData.agreement_flg;
        state.agreement_flg = apiResponseData.agreement_flg;

        state.userinfo.delete_flg = apiResponseData.delete_flg;
        
        // システム情報取得
        state.systeminfo.recruitment_closing_hour =
          apiResponseData.systeminfo.recruitment_closing_hour;
        state.systeminfo.recruitment_enable_change_hour =
          apiResponseData.systeminfo.recruitment_enable_change_hour;
        state.systeminfo.recruitment_worker_decline_enable_hour =
          apiResponseData.systeminfo.recruitment_worker_decline_enable_hour;

        // 通知状態取得
        state.notice_count.all = apiResponseData.notice_count.all;
        state.notice_count.unread = apiResponseData.notice_count.unread;

        // 未評価ジョブ状態
        state.unratedDialogFlg = apiResponseData.unrated_job['result'];
        state.unratedJobInfo = apiResponseData.unrated_job['job_information'];

        // キャンペーン情報設定
        const campaignInfoList:any = apiResponseData.campaign_list;
        if(campaignInfoList){
          if(campaignInfoList.length > 0){
            // 現状は1件のみ対応
            const campaignInfo = campaignInfoList[0];
            if(isCampaignDate(campaignInfo['start_ymd'], campaignInfo['end_ymd'])){
              state.campaignBannerInfo.href = campaignInfo['link_url'];
              state.campaignBannerInfo.img_url = `/img/campaign/${campaignInfo['banner_image']}`;
            }
          }
        }

        // ログイン中でなければ処理しない
        if (!state.login_status) {
          return;
        }
  
        // 退会している場合
        if(checkAccountClosed()){
          logoutAuto();
          return;
        }
  
        // 処理スキップ対象画面ならスキップ
        const currentPath = router.currentRoute.path;
        const arySkipPage = ['/terms/agree/', '/logout', '/after-logout'];
        const isSkipPage = (arySkipPage.indexOf(currentPath) !== -1);
        if (isSkipPage) {
          return;
        }
  
        // 規約同意していなければ規約同意画面に遷移
        if(!checkAgreement()){
          gotoTermsAgreePage();
          return;
        }
  
        // ログイン状態チェック
        checkLoginStatus();

      })
      .then(() => (state.loading = false))
      .catch((error) => {
        // ログインしていない場合はログイン状態解除
        if (error.response && error.response.status == 401) {
          state.login_status = false;
        }
      });
  };

  function isCampaignDate(fromYmd:string, toYmd:string){
    const nowDate = new Date();

    let fromDate = null;
    if(fromYmd != ''){
      const fromYmdArray = fromYmd.split("/");
      const fromYear:number = parseInt(fromYmdArray[0],10);
      const fromMonth:number = parseInt(fromYmdArray[1],10);
      const fromDay:number = parseInt(fromYmdArray[2],10);
      fromDate = new Date(fromYear, fromMonth - 1, fromDay);
    }

    let toDate = null;
    if(toYmd != ''){
      const toYmdArray = toYmd.split("/");
      const toYear:number = parseInt(toYmdArray[0],10);
      const toMonth:number = parseInt(toYmdArray[1],10);
      const toDay:number = parseInt(toYmdArray[2],10);
      toDate = new Date(toYear, toMonth - 1, toDay, 23, 59, 59);
    }

    if(fromDate == null || fromDate <= nowDate){
      if(toDate == null || nowDate <= toDate){
        return true;
      }
    }
    return false;
  }

  // ログアウト処理
  const logoutBase = async function() {
    state.userinfo.login_id = "";
    state.userinfo.password = "";
    state.login_status = false;
    localStorage.setItem("token", "");
    localStorage.setItem("agreement_flg", "");
  };

  // 自動ログアウト
  const logoutAuto = async function() {
    await logoutBase();

    router.push("/");
  };

  // 退会状態チェック
  const checkAccountClosed = function() {
    return (state.userinfo.delete_flg == "1");
  };

  // 規約同意状態チェック
  const checkAgreement = function() {
    return (state.agreement_flg == "1");
  };

  // 規約同意画面に遷移
  const gotoTermsAgreePage = function(query:any = null) {
    // redirectパラメーターがあれば保持して遷移
    if(query && query["redirect"]){
      const redirectUrl = query["redirect"];
      router.push({
        name: "LoginVpTermsAgree",
        query: { redirect: redirectUrl },
      });
    }
    else {
      router.push({
        name: "LoginVpTermsAgree"
      });
    }
  };

  // ログイン状態判定
  const checkLoginStatus = async function() {
    let tokenstr = localStorage.getItem("token");
    const refresh = localStorage.getItem("refresh");
    if (
      tokenstr === null ||
      tokenstr === "" ||
      refresh === null ||
      refresh === ""
    ) {
      state.login_status = false;
    } else {
      tokenstr = tokenstr.replace("jwt ", "");
      tokenstr = tokenstr.replace("JWT ", "");

      const params = {
        //tokenチェック
        refresh: refresh,
      };

      await axiosForToken
        .post("refresh", params, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          if (response.status == 200) {
            localStorage.setItem("token", "jwt " + response.data.access);
            localStorage.setItem("refresh", response.data.refresh);
            state.login_status = true;
          } else {
            state.login_status = false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.status == 401) {
            if (refresh !== null && refresh !== "") {
              //あとでrefresh token対応
              localStorage.clear();
              state.login_status = false;
            } else {
              localStorage.clear();
              state.login_status = false;
            }
          } else {
            localStorage.clear();
            state.login_status = false;
          }
        });
    }
  };

  return {
    get loading() {
      return state.loading;
    },
    get userAlert() {
      return state.userAlert;
    },
    get userError() {
      return state.userError;
    },
    get loginError() {
      return state.loginError;
    },
    get inquiryError() {
      return state.inquiryError;
    },

    gotoNetworkError() {
      router.push("/network-error");
    },

    gotoAccessError() {
      router.push("/access-error");
    },

    resetUserAlert() {
      return (state.userAlert.status = ""), (state.userAlert.message = "");
    },

    setUserAlert(s: string, m: string) {
      state.userAlert.status = s;
    },

    get systeminfo() {
      return state.systeminfo;
    },

    get userinfo() {
      return state.userinfo;
    },

    get notice_page_no() {
      return state.notice_page_no;
    },

    get notice_page_max_no() {
      return state.notice_page_max_no;
    },

    get notice_item() {
      return state.notice_item;
    },

    get notice_list() {
      return state.notice_list;
    },

    get notice_count() {
      return state.notice_count;
    },

    get login_status() {
      return state.login_status;
    },
    get inquiryinfo() {
      return state.inquiryInfo;
    },
    // ログイン規約に遷移時ローディング表示されるよう作成
    changeLoading(e: boolean) {
      state.loading = e;
    },

    //store LoginId
    // login_idに修正
    storeLoginId(login_id: string) {
      return (
        (state.userinfo.login_id = login_id)
      );
    },
    // store Password
    storePassword(password: string) {
      return (
        (state.userinfo.password = password)
      );
    },

    //inquiry name
    inquiryName(e: string) {
      return (
        (state.inquiryInfo.name = e)
      );
    },
    //inquiry mail_address
    inquiryMailAddress(e: string) {
      return (
        (state.inquiryInfo.mail_address = e)
      );
    },
    //inquiry tel
    inquiryTel(e: string) {
      return (
        (state.inquiryInfo.tel = e)
      );
    },
    //inquiry content
    inquiryContent(e: string) {
      return (
        (state.inquiryInfo.content = e)
      );
    },
    get withholding_slip() {
      return state.report.WithholdingSlip;
    },
    get pay_slip() {
      return state.report.PaySlip;
    },
    //マイページ支払明細書の日にち選択用に作成
    inputMonth(e: number) {
      state.report.PaySlip.selected_month = e;
    },
    //マイページ支払明細書の日にち選択用に作成
    inputYear(e: number) {
      state.report.PaySlip.selected_year = e;
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detailBankFile(event: any) {
      const files = event.target.files;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      state.userinfo.bank_id = files[0].name;
      state.userinfo.url = URL.createObjectURL(files[0]);
      state.userinfo.bank_image = files[0];
      formData.append("bank_image", files[0]);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detailUserFile(event: any, orientation: string) {
      const files = event.target.files;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      state.userinfo.user_image_url = URL.createObjectURL(files[0]);
      state.userinfo.user_image = files[0];
      state.userinfo.orientation = parseInt(orientation);

      formData.append("user_image", files[0]);
      formData.append("orientation", orientation);
    },

    async requestCustomerInfo() {
      return await requestCustomerInfo();
    },

    // login
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async login(query: any) {

      state.loginParams = {
        login_id: state.userinfo.login_id,
        password: state.userinfo.password,
      };
      state.login_status = false;
      state.loading = true;
      await axiosForLogin
        .post("auth/login", state.loginParams, {
          headers: {
            "content-type": "application/json",
          },
        })

        .then(async(response) => {
          if (!response || !response.data) {
            state.loading = false;
            return;
          }
          const apiResponse = response.data;

          const apiResponseData = apiResponse.data;

          localStorage.setItem("token", "jwt " + apiResponseData.token);
          localStorage.setItem("refresh", apiResponseData.refresh);
          localStorage.setItem("agreement_flg", apiResponseData.agreement_flg);

          state.userinfo.user_image_url = apiResponseData.user_image_path;
          state.userinfo.card_number = String(apiResponseData.card_number);

          state.userinfo.agreement_flg = String(apiResponseData.agreement_flg);
          state.agreement_flg = String(apiResponseData.agreement_flg);

          state.userinfo.jobpay_registered_flg = apiResponseData.jobpay_registered_flg;
          state.userinfo.jobpay_activation_flg = apiResponseData.jobpay_activation_flg;

          await requestCustomerInfo();
          
          state.loading = false;
        });
    },
    // ログアウト後画面遷移
    logout() {
      logoutBase();

      // router
      router.push("/after-logout");
    },

    // 画面遷移のないログアウト
    logoutForActivate() {
      logoutBase();
      
      this.setUserAlert("LOGOUT", "ログアウトしました");
    },

    // 自動ログアウト
    logoutAuto() {
      logoutBase();

      router.push("/");
    },

    logoutSilent() {
      logoutBase();
    },

    // 規約同意画面に遷移
    gotoTermsAgreePage(query:any = null) {
      return gotoTermsAgreePage(query);
    },

    // redirectパラメーターに応じてログイン直後に表示する画面の切り替えを行う
    gotoLoginFirstPage(query:any) {

      // ログインリンクパラメーターがあればログインリンク設定を取得
      let loginLinkSetting = null;
      if (query) {
        loginLinkSetting = LOGIN_LINK_SETTINGS.filter(
          (loginLinkSettingSearch) => {
            const regexp = new RegExp("^" + loginLinkSettingSearch.page_url + "(\\?[a-zA-Z0-9=&_\\-]+)?" + "(#[a-zA-Z0-9_\\-]+)?" + "$")
            if( regexp.test(query["redirect"]) ){
              return true;
            }
            return false;
          }
        )[0];
      }
      // ログインリンク設定が取得できていれば処理
      if (loginLinkSetting) {
        const loginLinkNextParams: { [key: string]: string } = {};
        loginLinkSetting["param_names"].forEach(function (
          linkParamName
        ) {
          const paramValue = query[linkParamName];
          if (paramValue === undefined) {
            return true;
          }
          loginLinkNextParams[linkParamName] = String(paramValue);
        });
        // 指定されたリンク先へ遷移
        const redirectQueryStr = Object.entries(loginLinkNextParams)
          .map((e) => {
              const key = e[0];
              const value = encodeURI(e[1]);
              return `${key}=${value}`;
          })
          .join("&");
        let redirectUrl = query["redirect"];
        if(redirectQueryStr != ""){
          redirectUrl += "?" + redirectQueryStr;
        }
        router.push(redirectUrl);
        return;
      }
      router.push("/");
    },

    // 規約同意フラグ取得
    get agreement_flg() {
      return state.agreement_flg;
    },
    // 規約同意
    async agreeTerms() {
      const params = "";

      state.userinfo.agreement_flg = "1";
      state.agreement_flg = "1";

      localStorage.setItem("agreement_flg", "1");
      if (state.login_status == true) {
        await axiosinstance
          .patch("agreement", params, {
            headers: {
              "content-type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          })

          .then((response) => {
            const apiResponse = response.data;
            const apiResponseData = apiResponse.data;

            state.userinfo.agreement_flg = apiResponseData.agreement_flg;
            state.agreement_flg = apiResponseData.agreement_flg;
          });
        return true;
      }
      return false;
    },
    // 規約非同意（cancel）
    cancelAgreeTerms() {
      state.userinfo.login_id = "";
      state.userinfo.password = "";
      state.login_status = false;
      localStorage.setItem("token", "");
      localStorage.setItem("agreement_flg", "");

      router.push("/terms/after-not-agree");
    },
    // 規約同意状態チェック
    checkAgreement() {
      return checkAgreement();
    },
    // 退会状態チェック
    checkAccountClosed() {
      return checkAccountClosed();
    },
    // ログイン状態判定
    async checkLoginStatus() {
      await checkLoginStatus();
    },
    // customerInfoを取得
    get customerInfo() {
      return state.customerInfo;
    },
    // ユーザー評価取得
    getUserRating() {
      axiosinstance
        .get("userRating", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response.data.evaluation_point) {
            state.userinfo.user_rating = response.data.evaluation_point;
          }
        });
    },
    // 応募履歴一覧取得
    get applicationHistory() {
      return state.applicationHistory;
    },
    // 応募履歴の日付
    get applicationHistoryDate() {
      return state.applicationHistoryDate;
    },
    // お気に入り
    get favorite() {
      return state.favorite;
    },
    // お気に入りリスト
    get favoritelist() {
      return state.favoritelist;
    },
    get favoriteIds() {
      return state.favoriteIds;
    },
    // サーバからお気に入りリストを取得GET
    getFavoriteJson() {
      if(localStorage.getItem("token")){
      state.loading = true;
      axiosinstance
        .get("favorite", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })

        .then((response) => (state.favoritelist = response.data))
        .then(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          (response) =>
            (state.favoriteIds = _.map(
              state.favoritelist,
              "job_information_id"
            ))
        )
        .then(() => (state.loading = false));
      // .catch(err => {
      //   state.loading = false;
      //   state.userError = err;
      // });
      }
    },
    //  サーバへお気に入りを登録
    postFavorite(c: string, j: string) {
      const params = {
        company_id: c,
        job_information_id: j,
      };
      state.loading = true;
      axiosinstance
        .post("favorite", params, {
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          state.favorite = response.data;
        })
        .then(() => (state.loading = false))
        .then(() => {
          this.getFavoriteJson();
        })
        .then(() => {
          this.setUserAlert("postFavorite", "お気に入りに登録しました");
        });
      // .catch(err => {
      //   state.loading = false;
      //   state.userError = err;
      // });
    },
    //  サーバからお気に入りを削除DELETE
    deleteFavorite(c: string, j: string) {
      const data = {
        company_id: c,
        job_information_id: j,
      };
      state.loading = true;
      axiosinstance
        .delete("favorite", {
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
          data,
        })
        .then((response) => {
          state.favorite = response.data;
        })
        .then(() => {
          this.getFavoriteJson();
        })
        .then(() => (state.loading = false))
        .then(() => {
          this.getFavoriteJson();
        })
        .then(() => {
          this.setUserAlert(
            "deleteFavorite",
            "お気に入りリストから削除しました"
          );
        });
      // .catch(err => {
      //   state.loading = false;
      //   state.userError = err;
      // });
    },
    // 応募するPOST
    postApplication(c: string, j: string, s: string) {
      const params = {
        company_id: c,
        job_information_id: j,
        shift_id: s,
      };
      state.loading = true;
      axiosinstance
        .post("applicationInfo", params, {
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })

        .then((response) => {
          state.application = response.data;
        })
        .then(() => (state.loading = false))
        .then(() => {
          this.setUserAlert("postApplication", "応募完了しました");
        })
        .then(() => {
          state.applicationHistoryAndStatusCode = [
            { application_status_code: "" },
          ];
        })
        .then(() => {
          this.getApplicationInfo(c, j);
        });
    },
    // 応募情報ステータス変更するPUT
    async patchApplication(
      c: string,
      j: string,
      a: string,
      s: string,
      p: number
    ) {
      const params = {
        company_id: c,
        job_information_id: j,
        application_status_code: a,
        shift_id: s,
        penalty_point: p,
      };

      const msg =
        a === "004004"
          ? "応募キャンセルしました"
          : a === "004009"
          ? "採用辞退しました"
          : "";

      state.loading = true;
      await axiosinstance
        .patch("applicationInfo", params, {
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          state.application = response.data;
        })
        .then(() => (state.loading = false))
        .then(() => {
          this.setUserAlert("postApplication", msg);
        })
        .then(() => {
          state.applicationHistoryAndStatusCode = [
            { application_status_code: "" },
          ];
        })
        // .then(() => {
        //   this.decideApplicationMonth(
        //     new Date().getFullYear() + "/" + new Date().getMonth() + 1
        //   );
        // })

        // .then(() => {
        //   router.push("/my-page");
        // });
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .then(() => {});
      await this.getApplicationInfo(c, j);
      await this.getApplicationPenalty();
    },
    // 応募情報ステータスを取得
    async getApplicationInfo(c: string, j: string) {
      if (!c || !j || !state.login_status) {
        return;
      }
      state.loading = true;
      await axiosinstance
        .get("applicationInfo", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            company_id: c,
            job_information_id: j,
          },
        })

        .then((response) => (state.applicationInfo = response.data))
        .then(() => (state.loading = false));
      // .catch(err => {
      //   state.loading = false;
      //   state.userError = err;
      // });
    },
    // 同日時応募チェックAPI(採用済みと被っているか)
    async getApplicationSameDateFlag(c: string, j: string) {
      if (!c || !j || !state.login_status) {
        return;
      }
      state.loading = true;
      await axiosinstance
        .get("applicationSameDate", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            company_id: c,
            job_information_id: j,
          },
        })

        .then((response) => (state.same_date_adoption_flg = response.data.same_date_adoption_flg))
        .then(() => (state.loading = false));
    },
    // 応募ペナルティを取得
    async getApplicationPenalty() {
      if (!localStorage.getItem("token")) {
        return;
      }
      state.loading = true;
      await axiosinstance
        .get("applicationPenalty", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => (state.penalty_point = response.data.penalty_point))
        .then(() => (state.loading = false));
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fixNoticeItem(notice_item: any) {
      notice_item["message_oneline_html"] = "";
      notice_item["message_html"] = "";
      if (notice_item["message"]) {
        // HTMLメッセージ
        if (notice_item.message_format == 1) {
          // メッセージ詳細表示用HTML
          notice_item["message_html"] = notice_item["message"];
          // URLリンク化
          notice_item["message_html"] = notice_item["message_html"].replace(
            regexp_url,
            regexp_makeLink
          );

          // メッセージ1行表示用HTML
          notice_item["message_oneline_html"] = notice_item["message"];
          // 改行除去
          notice_item["message_oneline_html"] = notice_item[
            "message_oneline_html"
          ].replace(/\n/g, "");
          // HTMLタグを除去
          notice_item["message_oneline_html"] = notice_item[
            "message_oneline_html"
          ].replace(/<[^>]*?>/g, "");
        }
        // テキストメッセージ
        else {
          // メッセージ詳細表示用HTML
          notice_item["message_html"] = notice_item["message"];
          // 改行タグ設定
          notice_item["message_html"] = notice_item["message_html"].replace(
            /\n/g,
            "<br>"
          );
          // URLリンク化
          notice_item["message_html"] = notice_item["message_html"].replace(
            regexp_url,
            regexp_makeLink
          );

          // メッセージ1行表示用HTML
          notice_item["message_oneline_html"] = notice_item["message"];
          // 改行除去
          notice_item["message_oneline_html"] = notice_item[
            "message_oneline_html"
          ].replace(/\n/g, "");
        }
      }

      return notice_item;
    },

    // 通知詳細を取得・既読
    async requestNoticeItem(notice_id: string) {
      if (!localStorage.getItem("token")) {
        return;
      }
      if (!notice_id) {
        return;
      }

      state.loading = true;

      const response = await axiosinstance
        .get("notice/item", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            notice_id: notice_id,
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .finally(() => {});
      if (!response) {
        state.loading = false;
        return;
      }

      state.notice_item = this.fixNoticeItem(response.data.notice_item);

      // 既読にする
      const responseNoticeRead = await axiosinstance
        .post(
          "notice/read",
          {
            notice_id: notice_id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {})
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .finally(() => {});
      if (!responseNoticeRead) {
        state.loading = false;
        return;
      }

      state.notice_count.all = responseNoticeRead.data.notice_count.all;
      state.notice_count.unread = responseNoticeRead.data.notice_count.unread;

      state.loading = false;
    },
    // 通知一覧・通知件数を取得
    async requestNoticeList(page_no: number) {
      if (!localStorage.getItem("token")) {
        return;
      }
      if (!page_no) {
        return;
      }
      state.loading = true;
      const response = await axiosinstance
        .get("notice/list", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            page_no: page_no,
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .finally(() => {});
      if (!response) {
        state.loading = false;
        return;
      }

      const notice_list = response.data.notice_list;
      for (let i = 0; i < notice_list.length; i++) {
        notice_list[i] = this.fixNoticeItem(notice_list[i]);
      }
      state.notice_list = notice_list;

      state.notice_page_no = response.data.notice_page_no;
      state.notice_page_max_no = response.data.notice_page_max_no;

      state.notice_count.all = response.data.notice_count.all;
      state.notice_count.unread = response.data.notice_count.unread;

      state.loading = false;
    },
    // 通知件数を取得(全件数・未読件数)
    async requestNoticeCount() {
      if (!localStorage.getItem("token")) {
        return;
      }
      state.loading = true;
      const response = await axiosinstance
        .get("notice/count", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .finally(() => {});
      if (!response) {
        return;
      }
      state.notice_count.all = response.data.notice_count.all;
      state.notice_count.unread = response.data.notice_count.unread;

      state.loading = false;
    },
    // 通知を全て既読にする
    async setNoticeReadAll() {
      if (!localStorage.getItem("token")) {
        return;
      }
      state.loading = true;
      const response = await axiosinstance
        .post(
          "notice/readall",
          {},
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {})
        .finally(() => {
          state.loading = false;
        });

      if (!response) {
        return;
      }

      // 通知を再取得
      this.requestNoticeList(state.notice_page_no);
    },
    // レコメンドメール配信再開・停止
    async setRecommendSubscribed(recommend_flg:number) {
      if (!localStorage.getItem("token")) {
        return;
      }
      state.loading = true;

      axiosinstance
      .post(
        "recommend",
        {
          "recommend_flg": recommend_flg
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
          // リコメンド設定再取得
          state.userinfo.recommend_flg = response.data.recommend_flg;
      })
      .catch((err) => {
        state.userError = err;
      })
      .finally(() => {
        state.loading = false;
      });
    }, 

    get applicationInfo() {
      return state.applicationInfo;
    },
    get penaltyPoint() {
      return state.penalty_point;
    },
    get sameDateAdoptionFlg() {
      return state.same_date_adoption_flg;
    },
    get applicationHistoryAndStatusCode() {
      return state.applicationHistoryAndStatusCode;
    },
    // 応募履歴の日付を取得
    async getApplicationHistoryDate() {
      state.loading = true;
      return axiosinstance
        .get("applicationHistoryDate", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })

        .then((response) => {
          state.applicationHistoryDate = response.data;
        })

        .then(() => {
          state.applicationHistoryDate = _.uniq(
            _.map(state.applicationHistoryDate, "date_status")
          );
        })
        .then(() => {
          state.applicationHistoryDate = _.orderBy(
            state.applicationHistoryDate,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (item: any) => {
              return item;
            },
            ["desc"]
          );
        })

        .then(() => (state.loading = false));
      // .catch(err => {
      //   state.loading = false;
      //   state.userError = err;
      // });
    },
    // 応募履歴の年月を決定する GET applicationHistory
    decideApplicationMonth(m: any) {
      if (m !== "0000/00" && m !== undefined) {
        state.loading = true;
        axiosinstance
          .get("applicationHistory", {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
            params: {
              year_month: m,
            },
          })

          .then((response) => (state.applicationHistory = response.data))

          .then(() => (state.loading = false))
          .then(() => this.applicationStatusCode());
        // .catch(err => {
        //   state.loading = false;
        //   state.userError = err;
        // });
      } else {
      }
    },

    applicationHistoryReset() {
      return (
        (state.applicationHistory = [{ application_status_code: "" }]),
        (state.applicationHistoryAndStatusCode = [
          { application_status_code: "" },
        ])
      );
    },

    applicationStatusCode() {
      _.forEach(state.applicationHistory, (value: any, key: any) => {
        axiosinstance
          .get("applicationInfo", {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
            params: {
              company_id: value.company_id,
              job_information_id: value.job_information_id,
            },
          })
          .then((response) => (state.applicationInfo = response.data))
          .then(
            () =>
              (state.applicationHistory[key].application_status_code =
                state.applicationInfo[0].application_status_code)
          )
          .then(() => {
            state.applicationHistoryAndStatusCode = Array.from(
              state.applicationHistory
            );
          });
        // .catch(err => {
        //   state.loading = false;
        //   state.userError = err;
        // });
      });
    },

    // 月リスト
    get monthlist() {
      return state.monthlist;
    },
    // work_month生成・取得
    thisYearMonth() {
      const today = new Date();
      const thisYear = today.getFullYear();
      const thisMonth = today.getMonth() + 1;
      state.workYear = thisYear;
      state.workMonth = thisMonth;
      this.getSalaryJson();
    },
    get workYear() {
      return state.workYear;
    },
    get workMonth() {
      return state.workMonth;
    },

    setWorkMonth(m: number) {
      state.workMonth = m;
      this.getSalaryJson();
    },
    minusYear() {
      state.workYear = state.workYear - 1;
      this.getSalaryJson();
    },
    plusYear() {
      state.workYear = state.workYear + 1;
      this.getSalaryJson();
    },
    minusPaySlipYear() {
      state.report.PaySlip.selected_year--;
    },
    plusPaySlipYear() {
      state.report.PaySlip.selected_year++;
    },
    setPaySlipMonth(m: number) {
      return (state.report.PaySlip.selected_month = m);
    },
    setWithholdingSlipYear(year:number) {
      state.report.WithholdingSlip.selected_year = year;
    },

    // 金額一覧取得 GET`
    getSalaryJson() {
      state.salarylist = [];
      // state.loading = true;
      const month_string = ("00" + state.workMonth).slice(-2);
      axiosinstance
        .get("salary", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            // work_month: "2019/12"
            work_month: String(state.workYear) + "/" + month_string,
          },
        })

        .then((response) => (state.salarylist = response.data));
      // .catch(err => {
      //   state.loading = false;
      //   state.userError = err;
      // });
      // .then(() => (state.loading = false));
    },

    get salarylist() {
      return state.salarylist;
    },

    // 銀行口座
    get bank() {
      return state.bank;
    },

    // 銀行支店
    get bankBranch() {
      return state.bank.branch_no;
    },

    // 銀行口座取得
    get bankAccount() {
      return state.bankAccount;
    },

    // CONSTANT 五十音
    get akasatana() {
      return state.akasatana;
    },

    // CONSTANT 五十音詳細
    get akasatanaDetail() {
      return state.akasatanaDetail;
    },

    // 銀行口座を取得
    async getBankAccount() {
      state.loading = true;
      await axiosinstance
        .get("bankAccount", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })

        .then((response) => {
          const customer_bank_info = response.data;
          if(customer_bank_info.bank_code){
            state.bankAccount.bank_no = customer_bank_info.bank_code;
            state.bankAccount.branch_no = customer_bank_info.branch_code;
            state.bankAccount.account_no = customer_bank_info.account_number;
            state.bankAccount.account_type = customer_bank_info.account_type;
            state.bankAccount.account_holder = customer_bank_info.account_name;
            state.bankAccount.request_date = customer_bank_info.created_at;
          }
          else {
            state.bankAccount.bank_no = customer_bank_info.BankCode;
            state.bankAccount.branch_no = customer_bank_info.BranchCode;
            state.bankAccount.account_no = customer_bank_info.AccountNumber;
            state.bankAccount.account_type = customer_bank_info.AccountType;
            state.bankAccount.account_holder = customer_bank_info.AccountName;
            state.bankAccount.request_date = customer_bank_info.RequestDate;
          }
        })
        .then(() => (state.loading = false));
      // .catch(err => {
      //   state.loading = false;
      //   state.userError = err;
      // });
    },

    // 銀行口座を登録
    postBankAccount() {
      const hankaku_account_holder = filter.zenkakuToHankaku(
        filter.hiraToKana(state.bank.account_holder)
      );
      const params = {
        bank_no: state.bank.bank_no,
        branch_no: state.bank.branch_no,
        account_type: state.bank.account_type,
        account_no: state.bank.account_no,
        account_holder: hankaku_account_holder,
        bank_image: state.userinfo.bank_image,
      };

      const regex = new RegExp(
        /[^\uFF66-\uFF9F\u30A1-\u30F6\u3041-\u3096\x20\u3000]/
      );
      if (!regex.test(hankaku_account_holder)) {
        if (
          params.bank_no &&
          params.branch_no &&
          params.account_type &&
          params.account_no &&
          params.account_holder
        ) {
          state.loading = true;
          formData.append("bank_no", params.bank_no);
          formData.append("branch_no", params.branch_no);
          formData.append("account_type", params.account_type);
          formData.append("account_no", params.account_no);
          formData.append("account_holder", params.account_holder);

          axiosinstance
            .post("bankAccount", formData, {
              headers: {
                "content-type": "multipart/form-data",
                Authorization: localStorage.getItem("token"),
              },
            })

            .then((response) => {
              state.bankAccount = response.data;
            })

            .then(() => {
              state.userAlert.status = "postBankAccount";
              state.userAlert.message = "銀行口座を登録しました";
            })
            .then(() => router.push("/my-page"))

            .then(() => (state.loading = false))
            .catch((err) => {
              state.loading = false;
              state.userError = err;
            });
        } else {
          state.userAlert.status = "postBankAccount";
          state.userAlert.message =
            "全ての項目を入力してください\n（空欄不可）";
        }
      } else {
        state.userAlert.status = "postBankAccount";
        state.userAlert.message = "口座名義は\nカタカナで入力してください";
      }
    },

    // ユーザー画像をアップロード
    postUserImage() {
      state.loading = true;
      axiosinstance
        .post("userImageUpload", formData, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        })

        .then((response) => {
          state.userinfo.user_image_url = response.data.user_image_path;
        })
        // 現在のパスと同じパスに対して移動するとエラーになるので、コールバック指定
        .then(() => router.push("/my-page",() => {}))

        .then(() => (state.loading = false))
        .catch((err) => {
          state.loading = false;
          state.userError = err;
        });
    },

    // 口座種類をストア
    storeAccountType(e: string) {
      return (
        (state.bank.account_type = e)
      );
    },

    // 口座番号をストア
    storeAccountNumber(e: string) {
      if (!isNaN(Number(e))) {
        return (
          (state.bank.account_no = e)
        );
      } else {
        state.bank.account_no = "半角数字で入力して下さい";
        const deleteError = () => {
          state.bank.account_no = "";
        };
        setTimeout(deleteError, 1000);
      }
    },
    // 口座名義をストア
    storeAccountHolder(e: any) {
      state.bank.account_holder = e;
      // const regex = new RegExp(/(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*/);
      // if (regex.test(e)) {
      //   return (
      //     (state.bank.account_holder = filter.hiraToKana(e)),
      //   );
      // } else {
      // }
    },

    // 銀行リスト取得
    bankjson() {
      state.loading = true;
      axiosinstance
        .get("bank", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })

        .then((response) => (state.banklist = response.data))
        // .catch(function(error) {
        //   state.loading = false;
        //   if (error.response) {
        //     // The request was made and the server responded with a status code
        //     // that falls out of the range of 2xx
        //   } else if (error.request) {
        //     // The request was made but no response was received
        //     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        //     // http.ClientRequest in node.js
        //   } else {
        //     // Something happened in setting up the request that triggered an Error
        //   }
        // })
        .then(() => {
          state.refinedlist = {};
          const new_refinedlist: any[] = [];
          _.forEach(state.refinedLetter, (letter: string) => {
            const tempObj = _.filter(state.banklist, (item: any) => {
              return item.bank_kana.charAt(0) === letter;
            });

            for (const key in tempObj) {
              new_refinedlist.push(tempObj[key]);
            }
            state.refinedlist = new_refinedlist;

          });
        })
        .then(() => {
          state.citybanklist = _.filter(state.banklist, { bank_flg: "1" });
        })
        .then(() => {
          state.netbanklist = _.filter(state.banklist, { bank_flg: "2" });
        })
        .then(() => (state.loading = false))
        .then(() => {
        });
    },

    // 支店リストを取得
    bankbranchjson(bank_no: string) {
      state.loading = true;
      axiosinstance
        .get("bankBranch", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            bank_no: bank_no,
          },
        })

        .then((response) => (state.bankbranchlist = response.data))
        .then(() => (state.loading = false));
      // .catch(err => {
      //   state.loading = false;
      //   state.userError = err;
      // });
    },
    // 絞り込み文字
    get refinedLetter() {
      return state.refinedLetter;
    },
    // 絞り込まれたリスト
    get refinedlist() {
      return state.refinedlist;
    },
    // 絞り込まれた銀行リスト
    get refinedbranchlist() {
      return state.refinedbranchlist;
    },
    // 都市銀行リスト
    get citybanklist() {
      return state.citybanklist;
    },
    // ネット銀行リスト
    get netbanklist() {
      return state.netbanklist;
    },
    // 銀行リスト
    get banklist() {
      return state.banklist;
    },
    // 支店リスト
    get bankbranchlist() {
      return state.bankbranchlist;
    },

    // 銀行決定
    decideBank(bank_no: string, bank_name: string) {
      this.bankbranchjson(bank_no);
      state.bank.bank_no = bank_no;
      state.bank.bank_name = bank_name;
      state.bank.branch_no = "";
      state.bank.branch_name = "";
      state.refinedbranchlist = "";
      state.refinedLetter = "";
    },
    // 支店決定
    decideBankBranch(branch_no: string, branch_name: string) {
      state.bank.branch_no = branch_no;
      state.bank.branch_name = branch_name;
      state.refinedLetter = "";
    },
    // 入力のクリア（銀行）
    clearBankInput() {
      state.bank.bank_no = "";
      state.bank.bank_name = "";
      state.bank.branch_no = "";
      state.bank.branch_name = "";
      state.bank.account_type = "";
      state.bank.account_no = "";
      state.bank.account_holder = "";

      state.refinedbranchlist = "";
      state.refinedLetter = "";
    },

    // 最初の文字を決定
    decideFirstLetter(g: any) {
      state.refinedLetter = g;
      const new_refinedlist: any[] = [];
      state.refinedlist = {};
      _.forEach(state.refinedLetter, (letter: string) => {
        const tempObj = _.filter(state.banklist, (item: any) => {
          return item.bank_kana.charAt(0) === letter;
        });

        for (const key in tempObj) {
          new_refinedlist.push(tempObj[key]);
        }
      });
      state.refinedlist = new_refinedlist;
    },
    // 最初の文字を決定(支店)
    decideBranchFirstLetter(g: any) {
      state.refinedLetter = g;
      state.refinedbranchlist = {};
      const new_refinedbranchlist: any[] = [];
      _.forEach(state.refinedLetter, (letter: string) => {

        const tempObj = _.filter(state.bankbranchlist, (item: any) => {
          return item.branch_kana.charAt(0) === letter;
        });

        for (const key in tempObj) {
          new_refinedbranchlist.push(tempObj[key]);
        }
        // Object.assign(state.refinedbranchlist, tempObj);
      });
      state.refinedbranchlist = new_refinedbranchlist;
    },

    // 詳細検索用
    setAkasatanaDetail(g: any[]) {
      state.akasatanaDetail = g;
    },

    // スキル一覧の取得GET
    skilljson() {
      // const api = axios.create();
      state.loading = true;
      axios
        .all([
          axiosinstance
            .get("userSkill", {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
              params: {},
            })
            .then((responseuserskill) => {
              (state.userSkill = responseuserskill.data),
                (state.skillValues = responseuserskill.data);
            }),
          // .catch(err => {
          //   state.loading = false;
          //   state.userError = err;
          // }),

          axiosinstance
            .get("skill", {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
              params: {},
            })
            .then((responseskill) => {
              (state.skilllist = responseskill.data);
            }),
          // .catch(err => {
          //   state.loading = false;
          //   state.userError = err;
          // })
        ])

        .then(() => {
          state.skilllistCategory = _.groupBy(state.skilllist, "major_code");
        })
        .then(() => (state.loading = false));
    },

    get userSkill() {
      return state.userSkill;
    },
    get skilllist() {
      return state.skilllist;
    },
    get skilllistCategory() {
      return state.skilllistCategory;
    },
    get dialog() {
      return state.dialog;
    },

    // スキルの選択（トグル）
    decideSkill(majour_code: string, small_code: string, checked: any) {
      if (checked == false) {
        _.remove(state.userSkill, { small_code: small_code });
      } else {
        const tempSkill: object = {
          major_code: majour_code,
          small_code: small_code,
        };
        state.userSkill.push(tempSkill);
      }
    },

    // GETユーザースキル
    getUserSkill() {
      state.loading = true;
      axiosinstance
        .get("userSkill", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {},
        })

        .then((response) => {
          state.userSkill = response.data;
        })
        .then(() => (state.loading = false));
      // .catch(err => {
      //   state.loading = false;
      //   state.userError = err;
      // });
    },

    // PUTユーザースキル スキル登録
    putUserSkill() {
      // 配列でPUT
      const params: any = [];

      _.forEach(state.userSkill, function (value: any, key: any) {
        params.push(_.pick(state.userSkill[key], ["small_code", "major_code"]));
      });
      // state.loading = true;
      axiosinstance
        .put("userSkill", params, {
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })

        .then((response) => {
          state.userSkill = response.data;
        });
      // .catch(err => {
      //   state.loading = false;
      //   state.userError = err;
      // });
      // .then(() => (state.loading = false));
    },

    resetUserSkill() {
      // リセットを送信PUt
      const params: any = [];
      state.loading = true;
      axiosinstance
        .put("userSkill", params, {
          headers: {
            "content-type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })

        .then((response) => {
          state.userSkill = response.data;
        })
        .then(() => (state.loading = false));
      // .catch(err => {
      //   state.loading = false;
      //   state.userError = err;
      // });
    },

    getTest() {
    },
    // 源泉徴収票の出力(PDF)
    outputPdfWithholdingSlip(callback: any = undefined) {
      state.loading = true;
      axiosinstance.defaults.timeout = 30000;
      axiosinstance
        .get("pdf/withholding_slip", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            target_year: state.report.WithholdingSlip.selected_year,
          },
          responseType: "json",
        })
        .then((get_response) => {
          if (get_response.data.length == 0) {
            return alert("該当する源泉徴収票が存在しません。");
          }
          axiosinstance
            .post("pdf/withholding_slip", get_response.data, {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
              params: {
                target_year: state.report.WithholdingSlip.selected_year,
              },
              responseType: "blob",
            })
            .then((post_response) => {
              const blob = new Blob([post_response.data], {
                type: "application/pdf",
              });
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                // Microsoft Internet Explorer
                const pdfName = "withholding_slip.pdf";
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                window.navigator.msSaveOrOpenBlob(blob, pdfName);
              } else {
                // Google Chrome & Mozilla Firefox
                const windowOpen = window.open(window.URL.createObjectURL(blob), "_blank");
                // Safari
                if(!windowOpen){
                  location.href = window.URL.createObjectURL(blob);
                }
              }
            });
        })
        .then(() => {
          if (callback !== undefined) {
            callback();
          }
        })
        .finally(() => (state.loading = false));
      axiosinstance.defaults.timeout = 3500;
    },
    // 労働条件通知書の出力(PDF)
    outputPdfWorkingConditionsNotice(
      job_information_id: number,
      company_id: number,
      callback: any = undefined
    ) {
      state.loading = true;
      axiosinstance.defaults.timeout = 30000;
      axiosinstance
        .get("pdf/working_conditions_notice", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            job_information_id: job_information_id,
            company_id: company_id,
          },
          responseType: "json",
        })
        .then((get_response) => {
          if (Object.keys(get_response.data).length == 0) {
            return alert("該当する労働条件通知書が存在しません。");
          }
          axiosinstance
            .post("pdf/working_conditions_notice", get_response.data, {
              headers: {
                "content-type": "application/json",
                Authorization: localStorage.getItem("token"),
              },
              responseType: "blob",
            })
            .then((post_response) => {
              const blob = new Blob([post_response.data], {
                type: "application/pdf",
              });
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                // Microsoft Internet Explorer
                const pdfName = "working_conditions_notice.pdf";
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                window.navigator.msSaveOrOpenBlob(blob, pdfName);
              } else {
                // Google Chrome & Mozilla Firefox
                const windowOpen = window.open(window.URL.createObjectURL(blob), "_blank");
                // Safari
                if(!windowOpen){
                  location.href = window.URL.createObjectURL(blob);
                }
              }
            });
        })
        .then(() => {
          if (callback !== undefined) {
            callback();
          }
        })
        .finally(() => (state.loading = false));
      axiosinstance.defaults.timeout = 3500;
    },
    // 給与明細の出力(PDF)
    outputPdfPaySlip(callback: any = undefined) {
      state.loading = true;
      axiosinstance.defaults.timeout = 30000;
      axiosinstance
        .get("pdf/pay_slip", {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: {
            target_year: state.report.PaySlip.selected_year,
            target_month: state.report.PaySlip.selected_month,
          },
          responseType: "json",
        })
        .then((get_response) => {
          if (Object.keys(get_response.data).length == 0) {
            return alert("該当する支払い明細が存在しません。");
          }
          axiosinstance
            .post("pdf/pay_slip", get_response.data, {
              headers: {
                "content-type": "application/json",
                Authorization: localStorage.getItem("token"),
              },
              responseType: "blob",
            })
            .then((post_response) => {
              const blob = new Blob([post_response.data], {
                type: "application/pdf",
              });
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                // Microsoft Internet Explorer
                const pdfName = "pay_slip.pdf";
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                window.navigator.msSaveOrOpenBlob(blob, pdfName);
              } else {
                // Google Chrome & Mozilla Firefox
                const windowOpen = window.open(window.URL.createObjectURL(blob), "_blank");
                // Safari
                if(!windowOpen){
                  location.href = window.URL.createObjectURL(blob);
                }
              }
            });
        })
        .then(() => {
          if (callback !== undefined) {
            callback();
          }
        })
        .finally(() => (state.loading = false));
      axiosinstance.defaults.timeout = 3500;
    },

    // お問い合わせ
    inquiry(query: any) {

      const params = {
        name: state.inquiryInfo.name,
        mail_address: state.inquiryInfo.mail_address,
        tel: state.inquiryInfo.tel,
        content: state.inquiryInfo.content,
      };
      state.loading = true;
      axiosinstanceForInquiry
        .post("inquiry", params, {
          headers: {
            "content-type": "application/json",
          },
        })

        .then((response) => {

          if (response != undefined) {
            state.loading = false;
            state.inquiryInfo.complete = true;
          } else {
            let errMessage = "";
            for (const [key, value] of Object.entries(
              state.inquiryError.data
            )) {
              let label = "";
              switch (key) {
                case "name":
                  label = "名前";
                  break;
                case "mail_address":
                  label = "メールアドレス";
                  break;
                case "tel":
                  label = "電話番号";
                  break;
                case "content":
                  label = "問い合わせ内容";
                  break;
              }
              errMessage += `${label}: ${value}\n`;
              this.setUserAlert("errMessage", errMessage);
            }
          }
        })
        .then(() => (state.loading = false));
    },

    // お問い合わせ
    resetInquiry(query: any) {

      state.inquiryInfo.name = "";
      state.inquiryInfo.mail_address = "";
      state.inquiryInfo.tel = "";
      state.inquiryInfo.content = "";
      state.inquiryInfo.complete = false;

      state.inquiryError.data = {
        name: [],
        mail_address: [],
        tel: [],
        content: [],
      };
    },

    // 最新の勤怠の未評価チェック
    async checkUnrated() {
      return await axiosinstance
      .get("member/job-rating/unrated", {
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      })
      .then((response) => {
        const responseData:any = response.data;

        state.unratedDialogFlg = responseData['result'];
        state.unratedJobInfo = responseData['job_information'];

        return responseData;
      })
    },
    get unratedDialogFlg() {
      return state.unratedDialogFlg;
    },
    set unratedDialogFlg(value:any) {
      state.unratedDialogFlg = value;
    },
    get unratedJobInfo() {
      return state.unratedJobInfo;
    },

    get campaignBannerInfo() {
      return state.campaignBannerInfo;
    },
  };
}

export type UserStore = ReturnType<typeof userStore>;
