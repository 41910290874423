










import {
  createComponent,
  computed,
  inject,
  onMounted,
} from "@vue/composition-api";
import SearchKey from "@/components/search-key";
import DisplayUIKey from "@/components/display-ui-key";
import UserKey from "@/components/user-key";

import Button from "@/components/atoms/button/Button.vue";
import PageTitle from "@/components/atoms/text/PageTitle.vue";
// import SearchInputBoxMainVue from "./SearchInputBoxMain.vue";

import Bank from "@/components/molecules/Bank.vue";
import SelectBank from "@/components/molecules/SelectBank.vue";
import SelectBankBranch from "@/components/molecules/SelectBankBranch.vue";
import SelectBankFirstLetter from "@/components/molecules/SelectBankFirstLetter.vue";

export default createComponent({
  setup() {
    const search = inject(SearchKey);
    if (!search) {
      throw new Error(`${SearchKey} is not provided`);
    }
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const currentBankComponent = computed(() => displayui.currentBankComponent);
    onMounted(async() => {
      await user.requestCustomerInfo();
      displayui.leaveSearching();
      displayui.leaveDrawer();
      displayui.leaveHeaderSub();
      displayui.leaveHeaderMyPage();
    });

    return { currentBankComponent };
  },

  components: {
    Bank: Bank,
    SelectBank: SelectBank,
    SelectBankBranch: SelectBankBranch,
    SelectBankFirstLetter: SelectBankFirstLetter,
    Button,
    PageTitle,
  },
});
