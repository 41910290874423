












































import { createComponent, onMounted, onUnmounted, inject, ref, reactive, computed } from "@vue/composition-api";
import DisplayUIKey from "@/components/display-ui-key";
import JobKey from "@/components/job-key";
import UserKey from "@/components/user-key";
import SearchKey from "@/components/search-key";
import ListView from "@/components/templates/ListView.vue";
// import Button from "@/components/atoms/button/Button.vue";

export default createComponent({
  setup(props, context) {
    const displayui = inject(DisplayUIKey);
    if (!displayui) {
      throw new Error(`${DisplayUIKey} is not provided`);
    }
    const job = inject(JobKey);
    if (!job) {
      throw new Error(`${JobKey} is not provided`);
    }
    const user = inject(UserKey);
    if (!user) {
      throw new Error(`${UserKey} is not provided`);
    }
    const search = inject(SearchKey);
    if (!search) {
      throw new Error(`${SearchKey} is not provided`);
    }

    const login_status = computed(() => user.login_status);

    const sortByTabIndex = (index:number) => job.sortByTabIndex(index);
    const searchParams = computed(() => search.searchParams);
  
    const onHeaderSub = displayui.onHeaderSub;
    const leaveHeaderMyPage = displayui.leaveHeaderMyPage;

    onUnmounted(async () => {
      displayui.debug_log("Top.vue onUnmounted");
      window.removeEventListener("popstate", handlePopstate);
    });

    onMounted(async () => {
      displayui.debug_log("Top.vue onMounted");
      window.addEventListener("popstate", handlePopstate);
      await init();
    });

    // 進む・戻るボタンで画面遷移
    const handlePopstate = async function(){
      displayui.debug_log("Top.vue handlePopstate");
      await init();
    }

    // 画面初期処理
    const init = async function(){
      displayui.debug_log("Top.vue init");

      // データ取得が終わるまで画面非表示
      document.getElementById('top-container')!.style.visibility = "hidden";

      // 会員情報を取得(+ログイン状態チェック)
      await user.requestCustomerInfo();

      // クエリ取得
      const query:any = context.root.$route.query;

      displayui.leaveSearchingClose();
      
      // URLから検索条件を取得して検索
      search.loadJobSearchFromQuery(query);
      search.collectData();
      await job.searchjobjson(searchParams);

      onHeaderSub();
      leaveHeaderMyPage();

      // 直前に表示していた求人までスクロール
      scrollToJob(job.getViewJobKey());
      job.clearViewJobKey();

      // データ取得が終わったら画面表示
      document.getElementById('top-container')!.style.visibility = "visible";
    };

    // 指定求人までスクロール
    function scrollToJob(jobKey:string) {
      try {
        const jobTopElem = document.getElementById('job-list-top');
        if(!jobTopElem) return;
        const jobElems = jobTopElem.querySelectorAll('[data-job-key="'+jobKey+'"]');
        if(!jobElems) return;
        const jobElem = jobElems[0];
        if(!jobElem) return;

        const targetDOMRect = jobElem.getBoundingClientRect();
        const targetTop = targetDOMRect.top + window.pageYOffset - 50;
        window.scrollTo({
          top: targetTop,
          behavior: 'auto'
        });
      } catch(e){}
    }
    (window as any).scrollToJob = scrollToJob;

    return {
      user,
      job,
      sortByTabIndex,
      login_status,
    };
  },

  components: {
    ListView
    // EvaluationModal
  },
});
