import { render, staticRenderFns } from "./Top.vue?vue&type=template&id=9b2f1244&scoped=true&"
import script from "./Top.vue?vue&type=script&lang=ts&"
export * from "./Top.vue?vue&type=script&lang=ts&"
import style0 from "./Top.vue?vue&type=style&index=0&id=9b2f1244&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b2f1244",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VIcon,VImg,VTab,VTabItem,VTabs,VTabsItems})
